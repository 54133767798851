
.container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 90%;
}

.prompt {
  margin: 1em 0;
  font-size: 14px;
}
