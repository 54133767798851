
.container {
  box-sizing: border-box;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 98;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: 50%;
  height: 100%;
  padding: 8px 0;
  font-size: 14px;
  background-color: white;
  overflow-y: scroll;
  overflow-x: hidden;
}

.header {
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  padding: 0 6px;
}

.heading {
  margin: 0 6px;
  font-size: 14px;
  color: dimgray;
}

.lineBreak {
  margin-bottom: 0;
}

.section {
  width: 100%;
  margin: 0 0 24px 0;
}

.row {
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  margin: 6px 0;
  padding: 0 6px;
  border-bottom: dimgray solid 1px;
}

.rightAlign {
  margin: 0 0 0 auto;
}

@media only screen and (max-width: 500px) {
  .container {
    width: 100%;
  }
}
