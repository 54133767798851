
.container {
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  height: 100%;
  background-color: white;
}

.sectionContainer {
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  width: 80%;
  height: 100%;
  margin: 0;
  padding: 2em;

  text-align: center;
}

.articleContainer {
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 12px 0;
}

.textBlock {
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  padding: 1em;
  font-size: 24px;
}

.text {
  text-align: left;
  margin: 0;
}

.linksContainer {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  width: 100%;
  margin: 1em 0;
}

.link {
  margin: 0 0 0 2em;
}

/* OTHER */

.h1 {
  font-size: 24px;
  width: 100%;
  text-align: left;
  margin: 12px 0 0 0;
}

.h2 {
  font-size: 20px;
}

.img {
  width: 50%;
}

.grnText {
  color: #0a720a;
}

.lineFadedRight {
  box-sizing: border-box;
  height: 1px;
  width: 100%;
  margin: 12px 0;
  background: linear-gradient(to right, #121212 55%, #ffffff 100%)
}

.lineFadedLeft {
  box-sizing: border-box;
  height: 1px;
  width: 100%;
  margin: 12px 0;
  background: linear-gradient(to right, #ffffff 0%, #121212 45%)
}


@media only screen and (max-width: 500px) {
  .sectionContainer {
    width: 100%;
  }
  .articleContainer {
    flex-direction: column;
    align-items: center;
  }
  .img {
    width: 100%;
  }
  .text {
    text-align: center;
  }
  .linksContainer {
    justify-content: center;
  }
  .link {
    margin: 0 2em;
  }
}
