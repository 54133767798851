
.navMenu {
  position: absolute;
  top: 50px;
  right: 0;
  z-index: 100;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  width: 50%;
  height: calc(100vh - 50px);
  background-color: white;
}

.navMenuLink {
  margin: 1rem 0;
}

.logInSignUpButtons {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.lineDivider {
  margin: 0 6px;
}

.footer {
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-start;
  width: 100%;
  height: 30%;
  margin: auto 0 0 0;
  padding: 8px;
  background-color: #393a3d;
  color: #ffffff;
  font-size: 12px;
}

.footerRow {
  width: 100%;
  margin: 0 0 12px 0;
}

.icon {
  height: 24px;
}

@media only screen and (max-width: 500px) {
  .navMenu {
    width: 100%;
  }
}
