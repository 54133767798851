
:root {
  --formBackground: #ffffff;
  --textGrey: #393a3d;
  --textBlack: #000000;
  --textWhite: #ffffff;

  --errorRed: #de535e;
  --buttonRed: #8b0000;
  --buttonBlue: #00008b;

  --lightGrey: #d3d3d3;
  --darkGrey: #696969;

  --lightGreen: #2ca11c;
  --darkGreen: #0a720a;

  --orange: #c46c00;
}
