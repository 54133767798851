
/*
  Standard US paper size in pixels = W: 2550, H: 3300. Landscape orientation -> flip width and height
  These sizes are halved to comply with canvas size restrictions in browsers.
  Pages need to be visible for querySelector, but hidden from user, therefore translate-y -150% and negative z-index.
 */
.usLetterSizePage {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: absolute;
  z-index: -1;
  transform: translateY(-150%);
  box-sizing: border-box;
  width: 1650px;
  height: 1275px;
  margin: 0;
  padding: 37px 73px;
}

.table {
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  height: 100%;
  border-collapse: collapse;
  border: 1px solid black;
}

.tableRow {
  display: flex;
  flex-direction: row;
  height: 50px;
}

.tableCell {
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 188px;
  border: 1px solid black;
  text-align: center;
}
