
.container {
  box-sizing: border-box;
  position: fixed;
  z-index: 99;
  top: 0;
  left: 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  height: 50px;
  background-color: white;
}

.heading {
  margin: 0 0 0 8px;
  font-size: 32px;
  color: var(--textBlack);
  font-family: 'Source Serif Pro', serif;
}

.navContainer {
  margin: 0 12px 0 auto;
}

.navButton {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0;
  padding: 2px 3px;
  background-color: inherit;
  border: none;
  cursor: pointer;
}

.logo {
  height: 32px;
  margin: 0 0 0 8px;
}
