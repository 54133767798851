
.container {
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin: 0;
}

.shiftsContainer {
  box-sizing: border-box;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(345px, max-content));
  grid-gap: 12px;
  justify-content: center;
  align-items: start;
  width: 100%;
  padding: 16px 0;
}

.heading {
  width: 100%;
  margin: 0 0 1em 0;
  text-align: center;
}

.errorMessage {
  color: darkred;
  font-size: 14px;
}
