@import url('https://fonts.googleapis.com/css2?family=Source+Serif+Pro&display=swap');

html, body, #root {
  height: 100%;
  color: var(--textGrey);
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

hr {
  width: 100%;
  background-color: grey;
}

.page {
  position: relative;
  top: 50px; /* offset sticky header */
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  height: calc(100% - 50px);
  background-color: #dcdee0;
  overflow-y: scroll;
  overflow-x: hidden;
}

a {
  text-decoration: none;
  font-weight: 600;
  color: var(--darkGreen)
}

a:hover {
  text-decoration: underline;
  color: var(--orange);
}
