
.container {
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 345px;
  margin: 0.5em;
  padding: 16px;
  border: grey solid 2px;
  border-radius: 10px;
  box-shadow: 10px 10px 8px -9px rgba(13,13,13,0.44);
  background-color: white;
}

.name {
  width: 100%;
  margin: 0;
  padding: 0;
  text-align: left;
}

.list {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  margin: 0.5em 0;
  padding: 0;
}

.listItem {
  box-sizing: border-box;
  margin: 4px 4px 4px 0;
  padding: 4px 8px;
  list-style: none;
  font-size: 12px;
  border: dimgray solid 2px;
  border-radius: 15px;
}

.deleteButton {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 0;
  padding: 4px;
  color: var(--buttonRed);
  font-weight: bold;
  font-size: 14px;
  background-color: inherit;
  border: none;
  cursor: pointer;
}

.buttonCol {
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  font-size: 14px;
  text-align: left;
}


