
.container {
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  height: 100%;
  padding: 0;
  overflow-y: scroll;
  overflow-x: hidden;
}

.employeesContainer {
  box-sizing: border-box;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(345px, max-content));
  grid-gap: 12px;
  justify-content: center;
  align-items: start;
  width: 100%;
  padding: 16px 0;
}

.loadingContainer {
  margin: 3em;
}
