
.container {
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  height: 100%;
}

.editorWindow {
  box-sizing: border-box;
  position: relative;
  top: 0;
  width: 100%;
  height: calc(100% - 50px);
  margin: 0;
  padding: 0;
  overflow-y: scroll;
  overflow-x: hidden;
}

.loadingContainer {
  margin: 3em;
}

.heading {
  width: 100%;
  margin: 1em 0 0 0;
  text-align: center;
}

.navHeading {
  margin: 0;
  text-align: center;
}

.dayHeader {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;
  height: 50px;
  background-color: white;
  color: #323232;
  font-family: 'Source Serif Pro', serif;
}

.navButton {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 48px;
  margin: 1em;
  padding: 6px;
  border: none;
  background-color: inherit;
  cursor: pointer;
}

.footer {
  position: fixed;
  z-index: 97;
  bottom: 0;
  left: 0;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  height: 50px;
  background-color: white;
}

/* Other */
.leftArrowIcon {
  transform: rotate(90deg);
  height: 36px;
}

.rightArrowIcon {
  transform: rotate(-90deg);
  height: 36px;
}
