@import url(https://fonts.googleapis.com/css2?family=Source+Serif+Pro&display=swap);
html, body, #root {
  height: 100%;
  color: var(--textGrey);
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

hr {
  width: 100%;
  background-color: grey;
}

.page {
  position: relative;
  top: 50px; /* offset sticky header */
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-justify-content: flex-start;
          justify-content: flex-start;
  -webkit-align-items: center;
          align-items: center;
  height: calc(100% - 50px);
  background-color: #dcdee0;
  overflow-y: scroll;
  overflow-x: hidden;
}

a {
  text-decoration: none;
  font-weight: 600;
  color: var(--darkGreen)
}

a:hover {
  text-decoration: underline;
  color: var(--orange);
}


:root {
  --formBackground: #ffffff;
  --textGrey: #393a3d;
  --textBlack: #000000;
  --textWhite: #ffffff;

  --errorRed: #de535e;
  --buttonRed: #8b0000;
  --buttonBlue: #00008b;

  --lightGrey: #d3d3d3;
  --darkGrey: #696969;

  --lightGreen: #2ca11c;
  --darkGreen: #0a720a;

  --orange: #c46c00;
}


.Nav_navMenu__2cpMN {
  position: absolute;
  top: 50px;
  right: 0;
  z-index: 100;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-justify-content: flex-start;
          justify-content: flex-start;
  -webkit-align-items: center;
          align-items: center;
  width: 50%;
  height: calc(100vh - 50px);
  background-color: white;
}

.Nav_navMenuLink__1ILMd {
  margin: 1rem 0;
}

.Nav_logInSignUpButtons__3nV1W {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
  width: 100%;
}

.Nav_lineDivider__2plb1 {
  margin: 0 6px;
}

.Nav_footer__QcEiR {
  box-sizing: border-box;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-justify-content: flex-end;
          justify-content: flex-end;
  -webkit-align-items: flex-start;
          align-items: flex-start;
  width: 100%;
  height: 30%;
  margin: auto 0 0 0;
  padding: 8px;
  background-color: #393a3d;
  color: #ffffff;
  font-size: 12px;
}

.Nav_footerRow__2Lc4p {
  width: 100%;
  margin: 0 0 12px 0;
}

.Nav_icon__1LDH8 {
  height: 24px;
}

@media only screen and (max-width: 500px) {
  .Nav_navMenu__2cpMN {
    width: 100%;
  }
}


.Header_container__3QgXz {
  box-sizing: border-box;
  position: fixed;
  z-index: 99;
  top: 0;
  left: 0;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
  -webkit-align-items: center;
          align-items: center;
  width: 100%;
  height: 50px;
  background-color: white;
}

.Header_heading__3vPb4 {
  margin: 0 0 0 8px;
  font-size: 32px;
  color: var(--textBlack);
  font-family: 'Source Serif Pro', serif;
}

.Header_navContainer__2Uafr {
  margin: 0 12px 0 auto;
}

.Header_navButton__3-PNZ {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  margin: 0;
  padding: 2px 3px;
  background-color: inherit;
  border: none;
  cursor: pointer;
}

.Header_logo__1iW0P {
  height: 32px;
  margin: 0 0 0 8px;
}


.Home_container__1cwlj {
  box-sizing: border-box;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-justify-content: flex-start;
          justify-content: flex-start;
  -webkit-align-items: center;
          align-items: center;
  width: 100%;
  height: 100%;
  background-color: white;
}

.Home_sectionContainer__3dr9V {
  box-sizing: border-box;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-justify-content: flex-start;
          justify-content: flex-start;
  -webkit-align-items: center;
          align-items: center;
  width: 80%;
  height: 100%;
  margin: 0;
  padding: 2em;

  text-align: center;
}

.Home_articleContainer__2b0NT {
  box-sizing: border-box;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
  -webkit-justify-content: flex-start;
          justify-content: flex-start;
  -webkit-align-items: flex-start;
          align-items: flex-start;
  padding: 12px 0;
}

.Home_textBlock__3-MKu {
  box-sizing: border-box;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
  height: 100%;
  padding: 1em;
  font-size: 24px;
}

.Home_text__3vcbn {
  text-align: left;
  margin: 0;
}

.Home_linksContainer__rHfOF {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
  -webkit-justify-content: flex-end;
          justify-content: flex-end;
  -webkit-align-items: center;
          align-items: center;
  width: 100%;
  margin: 1em 0;
}

.Home_link__3i05k {
  margin: 0 0 0 2em;
}

/* OTHER */

.Home_h1__1sywC {
  font-size: 24px;
  width: 100%;
  text-align: left;
  margin: 12px 0 0 0;
}

.Home_h2__1ht85 {
  font-size: 20px;
}

.Home_img__2Nfh1 {
  width: 50%;
}

.Home_grnText__1gWmU {
  color: #0a720a;
}

.Home_lineFadedRight__Oi6H2 {
  box-sizing: border-box;
  height: 1px;
  width: 100%;
  margin: 12px 0;
  background: linear-gradient(to right, #121212 55%, #ffffff 100%)
}

.Home_lineFadedLeft__3JCkQ {
  box-sizing: border-box;
  height: 1px;
  width: 100%;
  margin: 12px 0;
  background: linear-gradient(to right, #ffffff 0%, #121212 45%)
}


@media only screen and (max-width: 500px) {
  .Home_sectionContainer__3dr9V {
    width: 100%;
  }
  .Home_articleContainer__2b0NT {
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-align-items: center;
            align-items: center;
  }
  .Home_img__2Nfh1 {
    width: 100%;
  }
  .Home_text__3vcbn {
    text-align: center;
  }
  .Home_linksContainer__rHfOF {
    -webkit-justify-content: center;
            justify-content: center;
  }
  .Home_link__3i05k {
    margin: 0 2em;
  }
}


.LogInForm_container__3EjIM {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
  width: 90%;
}

.LogInForm_prompt__2yK5a {
  margin: 1em 0;
  font-size: 14px;
}

/*
This file contains the shared styles for all forms.
 */

/* LAYOUT CONTAINERS/WRAPPERS */
.Form_container__2_pUm {
  box-sizing: border-box;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
  width: 100%;
  margin: 0;
  padding: 0;
}

.Form_wrapper__1TdoX {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1000;
  box-sizing: border-box;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
  width: 100%;
  height: 100%;
  padding: 24px;
  background-color: rgba(0, 0, 0, 0.5);
}

.Form_formContainer__2gHnz {
  box-sizing: border-box;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
  min-width: 315px;
  max-width: 350px;
  margin: 0;
  padding: 24px;
  border: dimgray solid 1px;
  border-radius: 10px;
  box-shadow: 10px 10px 8px -10px rgba(13,13,13,0.44);
  font-size: 1em;
  background-color: var(--formBackground);
}

.Form_fieldContainer__2cDN4 {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
  width: 100%;
  margin: 6px 0;
}

.Form_textInputContainer__2t68X {
  position: relative;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
  width: 100%;
  margin: 0;
}

.Form_timeInputContainerFrom__1-t0B {
  box-sizing: border-box;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
  -webkit-justify-content: flex-start;
          justify-content: flex-start;
  -webkit-align-items: center;
          align-items: center;
  width: 50%;
  margin: 0;
  padding: 0;
}

.Form_timeInputContainerTo__3CrY5 {
  box-sizing: border-box;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
  -webkit-justify-content: flex-end;
          justify-content: flex-end;
  -webkit-align-items: center;
          align-items: center;
  width: 50%;
  margin: 0;
  padding: 0;
}

.Form_timeFieldset__1TAtR {
  box-sizing: border-box;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
  -webkit-justify-content: flex-start;
          justify-content: flex-start;
  -webkit-align-items: center;
          align-items: center;
  width: 100%;
  margin: 6px 0 12px 0;
  padding: 0 0 12px 0;
  font-size: 14px;
  border-bottom: dimgray solid 1px;
  outline: none;
}

/* INPUTS */
.Form_textInput__21yiz {
  box-sizing: border-box;
  width: 100%;
  margin: 6px 0;
  padding: 6px;
  font-size: 14px;
}

.Form_textInputError__zhbe8 {
  position: relative;
  box-sizing: border-box;
  width: 100%;
  margin: 6px 0;
  padding: 6px 24px 6px 6px;
  font-size: 14px;
  border-style: solid;
  border-color: var(--errorRed);
}

/* BUTTONS */
.Form_plusButton__28zPI {
  box-sizing: border-box;
  width: 200px;
  margin: 10px 0 24px 0;
  padding: 6px;
  border-radius: 10px;
  font-size: 14px;
  cursor: pointer;
  background-color: white;
  box-shadow: 10px 10px 8px -10px rgba(13,13,13,0.44);
}

.Form_buttonGreen__34r9v {
  box-sizing: border-box;
  width: 100%;
  height: 40px;
  margin: 10px 0;
  padding: 0.8em;
  border: none;
  border-radius: 24px;
  font-size: 14px;
  font-weight: bold;
  cursor: pointer;
  background-color: var(--darkGreen);
  color: var(--textWhite);
}

.Form_buttonGreen__34r9v:hover {
  background-color: #085a08;
}

.Form_buttonOrange__29dqF {
  box-sizing: border-box;
  width: 100%;
  height: 40px;
  margin: 10px 0;
  padding: 0.8em;
  border: none;
  border-radius: 24px;
  font-size: 14px;
  font-weight: bold;
  cursor: pointer;
  background-color: var(--orange);
  color: var(--textWhite);
}

.Form_buttonOrange__29dqF:hover {
  background-color: #975200;
}

.Form_textButton__1Iwzt {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
  margin: 0;
  padding: 4px;
  color: var(--buttonBlue);
  font-weight: bold;
  font-size: 14px;
  background-color: inherit;
  border: none;
  cursor: pointer;
}

/* LABELS */
.Form_textInputLabel__1lkV6 {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
  -webkit-justify-content: flex-start;
          justify-content: flex-start;
  -webkit-align-items: baseline;
          align-items: baseline;
  text-align: left;
  width: 100%;
  font-size: 16px;
}

.Form_timeInputLabel__2nLC9 {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: baseline;
          align-items: baseline;
  text-align: left;
  width: 100%;
  font-size: 16px;
  font-weight: bold;
}

.Form_timeLabel__1AxWC {
  margin: 0 4px 0 0;
  text-align: center;
  font-size: 16px;
}

/* OTHER */
.Form_formHeading__1qqj2 {
  width: 100%;
  margin: 0;
  text-align: center;
  font-size: 1.2em;
}

.Form_plusIcon__FQpoN {
  width: 24px;
}

.Form_errorText__2hQcH {
  margin: 0 0 0 auto;
  color: darkred;
  text-align: right;
  font-size: 12px;
  font-style: italic;
}

.Form_errorIcon__3RL44 {
  position: absolute;
  right: 8px;
  width: 16px;
}


.LogIn_container__a4NjU {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
  width: 100%;
  height: 100%;
}



.SignUpForm_container__1dE0q {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
  width: 90%;
}

.SignUpForm_prompt__1BeAK {
 margin: 1em 0;
 font-size: 14px;
}


.SignUp_container__3Re4w {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
  width: 100%;
  height: 100%;
}


.Employee_container__13GrQ {
  box-sizing: border-box;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
  width: 345px;
  margin: 0.5em;
  padding: 16px;
  border: grey solid 2px;
  border-radius: 10px;
  box-shadow: 10px 10px 8px -9px rgba(13,13,13,0.44);
  background-color: white;
}

.Employee_name__a3rA8 {
  width: 100%;
  margin: 0;
  padding: 0;
  text-align: left;
}

.Employee_list__3JQck {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
  -webkit-flex-wrap: wrap;
          flex-wrap: wrap;
  -webkit-justify-content: flex-start;
          justify-content: flex-start;
  -webkit-align-items: center;
          align-items: center;
  width: 100%;
  margin: 0.5em 0;
  padding: 0;
}

.Employee_listItem__2GF5A {
  box-sizing: border-box;
  margin: 4px 4px 4px 0;
  padding: 4px 8px;
  list-style: none;
  font-size: 12px;
  border: dimgray solid 2px;
  border-radius: 15px;
}

.Employee_deleteButton__3Xw3u {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
  margin: 0;
  padding: 4px;
  color: var(--buttonRed);
  font-weight: bold;
  font-size: 14px;
  background-color: inherit;
  border: none;
  cursor: pointer;
}

.Employee_buttonCol__9UPtL {
  box-sizing: border-box;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-justify-content: flex-start;
          justify-content: flex-start;
  -webkit-align-items: center;
          align-items: center;
  width: 100%;
  font-size: 14px;
  text-align: left;
}




.Employees_container__ldRsi {
  box-sizing: border-box;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-justify-content: flex-start;
          justify-content: flex-start;
  -webkit-align-items: center;
          align-items: center;
  width: 100%;
  height: 100%;
  padding: 0;
  overflow-y: scroll;
  overflow-x: hidden;
}

.Employees_employeesContainer__L72gT {
  box-sizing: border-box;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(345px, -webkit-max-content));
  grid-template-columns: repeat(auto-fit, minmax(345px, max-content));
  grid-gap: 12px;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: start;
          align-items: start;
  width: 100%;
  padding: 16px 0;
}

.Employees_loadingContainer__1BapR {
  margin: 3em;
}


.Day_container__3HRx0 {
  box-sizing: border-box;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
  width: 100%;
  margin: 0;
}

.Day_shiftsContainer__3ZY7_ {
  box-sizing: border-box;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(345px, -webkit-max-content));
  grid-template-columns: repeat(auto-fit, minmax(345px, max-content));
  grid-gap: 12px;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: start;
          align-items: start;
  width: 100%;
  padding: 16px 0;
}

.Day_heading__1bUQO {
  width: 100%;
  margin: 0 0 1em 0;
  text-align: center;
}

.Day_errorMessage__2LAnI {
  color: darkred;
  font-size: 14px;
}


.Shift_container__2-0lf {
  box-sizing: border-box;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
  width: 345px;
  margin: 8px;
  padding: 8px;
  border: dimgray solid 1px;
  border-radius: 10px;
  box-shadow: 10px 10px 8px -10px rgba(13,13,13,0.44);
  font-size: 14px;
  background-color: white;
}

.Shift_initialViewContainer__eUoxF {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
  -webkit-flex-wrap: nowrap;
          flex-wrap: nowrap;
  -webkit-justify-content: flex-start;
          justify-content: flex-start;
  -webkit-align-items: center;
          align-items: center;
  width: 100%;
}

.Shift_expandedViewContainer__3MaoN {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-justify-content: flex-start;
          justify-content: flex-start;
  -webkit-align-items: center;
          align-items: center;
  width: 100%;
  margin: 16px 0 8px 0;
}

.Shift_timeContainer__2ErdE {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
  -webkit-justify-content: flex-start;
          justify-content: flex-start;
  -webkit-align-items: baseline;
          align-items: baseline;
  -webkit-flex-wrap: nowrap;
          flex-wrap: nowrap;
  width: 130px;
  margin: 0 0 0 6px;
  word-break: keep-all;
  text-wrap: none;
}

.Shift_expandButton__3LyZp {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
  width: 40px;
  height: 40px;
  margin: 0 6px;
  padding: 0;
  background-color: inherit;
  border: none;
  cursor: pointer;
}

.Shift_deleteButton__1X9Jo {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
  margin: 0;
  padding: 4px;
  color: darkred;
  font-weight: bold;
  font-size: 14px;
  background-color: inherit;
  border: none;
  cursor: pointer;
}

.Shift_icon__3emeA {
  width: 24px;
}

.Shift_iconUpsideDown__3bwEN {
  width: 24px;
  -webkit-transform: rotate(180deg);
          transform: rotate(180deg);
}

.Shift_textRow__20AvT {
  box-sizing: border-box;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
  -webkit-justify-content: flex-start;
          justify-content: flex-start;
  -webkit-align-items: center;
          align-items: center;
  width: 100%;
  margin: 2px;
  font-size: 14px;
  text-align: left;
}

.Shift_buttonRow__35AwY {
  box-sizing: border-box;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
  -webkit-justify-content: flex-end;
          justify-content: flex-end;
  -webkit-align-items: center;
          align-items: center;
  width: 100%;
  font-size: 14px;
  text-align: left;
}

.Shift_textContainer__1Z2cD {
  box-sizing: border-box;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-justify-content: flex-start;
          justify-content: flex-start;
  -webkit-align-items: flex-start;
          align-items: flex-start;
  width: 100%;
  margin: 0 0 8px 0;
}

.Shift_status__ax-W1 {
  box-sizing: border-box;
  width: 16px;
  height: 16px;
  margin: 0 6px;
  padding: 4px;
  border: none;
  border-radius: 50%;
}

/* Inputs */
.Shift_selectInput__XAPwO {
  width: 140px;
  text-align-last: center;
}

/* Other */

.Shift_smHeading__1QJWJ {
  width: 100%;
  margin: 0;
}


.ScheduleEditor_container__1d8cB {
  box-sizing: border-box;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-justify-content: flex-start;
          justify-content: flex-start;
  -webkit-align-items: center;
          align-items: center;
  width: 100%;
  height: 100%;
}

.ScheduleEditor_editorWindow__19wD1 {
  box-sizing: border-box;
  position: relative;
  top: 0;
  width: 100%;
  height: calc(100% - 50px);
  margin: 0;
  padding: 0;
  overflow-y: scroll;
  overflow-x: hidden;
}

.ScheduleEditor_loadingContainer__SDddQ {
  margin: 3em;
}

.ScheduleEditor_heading__1rR2T {
  width: 100%;
  margin: 1em 0 0 0;
  text-align: center;
}

.ScheduleEditor_navHeading__d_Yce {
  margin: 0;
  text-align: center;
}

.ScheduleEditor_dayHeader__3mgS_ {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
  -webkit-flex-wrap: nowrap;
          flex-wrap: nowrap;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
  height: 50px;
  background-color: white;
  color: #323232;
  font-family: 'Source Serif Pro', serif;
}

.ScheduleEditor_navButton__rszT_ {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
  width: 48px;
  margin: 1em;
  padding: 6px;
  border: none;
  background-color: inherit;
  cursor: pointer;
}

.ScheduleEditor_footer__2moUW {
  position: fixed;
  z-index: 97;
  bottom: 0;
  left: 0;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
  -webkit-justify-content: flex-start;
          justify-content: flex-start;
  -webkit-align-items: center;
          align-items: center;
  width: 100%;
  height: 50px;
  background-color: white;
}

/* Other */
.ScheduleEditor_leftArrowIcon__OLLeY {
  -webkit-transform: rotate(90deg);
          transform: rotate(90deg);
  height: 36px;
}

.ScheduleEditor_rightArrowIcon__3JIYg {
  -webkit-transform: rotate(-90deg);
          transform: rotate(-90deg);
  height: 36px;
}


/*
  Standard US paper size in pixels = W: 2550, H: 3300. Landscape orientation -> flip width and height
  These sizes are halved to comply with canvas size restrictions in browsers.
  Pages need to be visible for querySelector, but hidden from user, therefore translate-y -150% and negative z-index.
 */
.Schedule_usLetterSizePage__2JqqC {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
  position: absolute;
  z-index: -1;
  -webkit-transform: translateY(-150%);
          transform: translateY(-150%);
  box-sizing: border-box;
  width: 1650px;
  height: 1275px;
  margin: 0;
  padding: 37px 73px;
}

.Schedule_table__2E73W {
  box-sizing: border-box;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-justify-content: flex-start;
          justify-content: flex-start;
  -webkit-align-items: center;
          align-items: center;
  width: 100%;
  height: 100%;
  border-collapse: collapse;
  border: 1px solid black;
}

.Schedule_tableRow__3VSKz {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
  height: 50px;
}

.Schedule_tableCell__1-bya {
  box-sizing: border-box;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
  width: 188px;
  border: 1px solid black;
  text-align: center;
}


.Overview_container__LfTLs {
  box-sizing: border-box;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 98;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-justify-content: flex-start;
          justify-content: flex-start;
  -webkit-align-items: flex-start;
          align-items: flex-start;
  width: 50%;
  height: 100%;
  padding: 8px 0;
  font-size: 14px;
  background-color: white;
  overflow-y: scroll;
  overflow-x: hidden;
}

.Overview_header__3r5AT {
  box-sizing: border-box;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
  -webkit-justify-content: flex-start;
          justify-content: flex-start;
  -webkit-align-items: center;
          align-items: center;
  width: 100%;
  padding: 0 6px;
}

.Overview_heading__eNmUp {
  margin: 0 6px;
  font-size: 14px;
  color: dimgray;
}

.Overview_lineBreak__1W53L {
  margin-bottom: 0;
}

.Overview_section__3FdrW {
  width: 100%;
  margin: 0 0 24px 0;
}

.Overview_row__n2ArB {
  box-sizing: border-box;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
  -webkit-justify-content: flex-start;
          justify-content: flex-start;
  -webkit-align-items: center;
          align-items: center;
  width: 100%;
  margin: 6px 0;
  padding: 0 6px;
  border-bottom: dimgray solid 1px;
}

.Overview_rightAlign__3NY6D {
  margin: 0 0 0 auto;
}

@media only screen and (max-width: 500px) {
  .Overview_container__LfTLs {
    width: 100%;
  }
}

