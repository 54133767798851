/*
This file contains the shared styles for all forms.
 */

/* LAYOUT CONTAINERS/WRAPPERS */
.container {
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin: 0;
  padding: 0;
}

.wrapper {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1000;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  padding: 24px;
  background-color: rgba(0, 0, 0, 0.5);
}

.formContainer {
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-width: 315px;
  max-width: 350px;
  margin: 0;
  padding: 24px;
  border: dimgray solid 1px;
  border-radius: 10px;
  box-shadow: 10px 10px 8px -10px rgba(13,13,13,0.44);
  font-size: 1em;
  background-color: var(--formBackground);
}

.fieldContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin: 6px 0;
}

.textInputContainer {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin: 0;
}

.timeInputContainerFrom {
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  width: 50%;
  margin: 0;
  padding: 0;
}

.timeInputContainerTo {
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  width: 50%;
  margin: 0;
  padding: 0;
}

.timeFieldset {
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  margin: 6px 0 12px 0;
  padding: 0 0 12px 0;
  font-size: 14px;
  border-bottom: dimgray solid 1px;
  outline: none;
}

/* INPUTS */
.textInput {
  box-sizing: border-box;
  width: 100%;
  margin: 6px 0;
  padding: 6px;
  font-size: 14px;
}

.textInputError {
  position: relative;
  box-sizing: border-box;
  width: 100%;
  margin: 6px 0;
  padding: 6px 24px 6px 6px;
  font-size: 14px;
  border-style: solid;
  border-color: var(--errorRed);
}

/* BUTTONS */
.plusButton {
  box-sizing: border-box;
  width: 200px;
  margin: 10px 0 24px 0;
  padding: 6px;
  border-radius: 10px;
  font-size: 14px;
  cursor: pointer;
  background-color: white;
  box-shadow: 10px 10px 8px -10px rgba(13,13,13,0.44);
}

.buttonGreen {
  box-sizing: border-box;
  width: 100%;
  height: 40px;
  margin: 10px 0;
  padding: 0.8em;
  border: none;
  border-radius: 24px;
  font-size: 14px;
  font-weight: bold;
  cursor: pointer;
  background-color: var(--darkGreen);
  color: var(--textWhite);
}

.buttonGreen:hover {
  background-color: #085a08;
}

.buttonOrange {
  box-sizing: border-box;
  width: 100%;
  height: 40px;
  margin: 10px 0;
  padding: 0.8em;
  border: none;
  border-radius: 24px;
  font-size: 14px;
  font-weight: bold;
  cursor: pointer;
  background-color: var(--orange);
  color: var(--textWhite);
}

.buttonOrange:hover {
  background-color: #975200;
}

.textButton {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 0;
  padding: 4px;
  color: var(--buttonBlue);
  font-weight: bold;
  font-size: 14px;
  background-color: inherit;
  border: none;
  cursor: pointer;
}

/* LABELS */
.textInputLabel {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: baseline;
  text-align: left;
  width: 100%;
  font-size: 16px;
}

.timeInputLabel {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: baseline;
  text-align: left;
  width: 100%;
  font-size: 16px;
  font-weight: bold;
}

.timeLabel {
  margin: 0 4px 0 0;
  text-align: center;
  font-size: 16px;
}

/* OTHER */
.formHeading {
  width: 100%;
  margin: 0;
  text-align: center;
  font-size: 1.2em;
}

.plusIcon {
  width: 24px;
}

.errorText {
  margin: 0 0 0 auto;
  color: darkred;
  text-align: right;
  font-size: 12px;
  font-style: italic;
}

.errorIcon {
  position: absolute;
  right: 8px;
  width: 16px;
}
