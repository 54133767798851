
.container {
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 345px;
  margin: 8px;
  padding: 8px;
  border: dimgray solid 1px;
  border-radius: 10px;
  box-shadow: 10px 10px 8px -10px rgba(13,13,13,0.44);
  font-size: 14px;
  background-color: white;
}

.initialViewContainer {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
}

.expandedViewContainer {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  margin: 16px 0 8px 0;
}

.timeContainer {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: baseline;
  flex-wrap: nowrap;
  width: 130px;
  margin: 0 0 0 6px;
  word-break: keep-all;
  text-wrap: none;
}

.expandButton {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  margin: 0 6px;
  padding: 0;
  background-color: inherit;
  border: none;
  cursor: pointer;
}

.deleteButton {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 0;
  padding: 4px;
  color: darkred;
  font-weight: bold;
  font-size: 14px;
  background-color: inherit;
  border: none;
  cursor: pointer;
}

.icon {
  width: 24px;
}

.iconUpsideDown {
  width: 24px;
  transform: rotate(180deg);
}

.textRow {
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  margin: 2px;
  font-size: 14px;
  text-align: left;
}

.buttonRow {
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  width: 100%;
  font-size: 14px;
  text-align: left;
}

.textContainer {
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
  margin: 0 0 8px 0;
}

.status {
  box-sizing: border-box;
  width: 16px;
  height: 16px;
  margin: 0 6px;
  padding: 4px;
  border: none;
  border-radius: 50%;
}

/* Inputs */
.selectInput {
  width: 140px;
  text-align-last: center;
}

/* Other */

.smHeading {
  width: 100%;
  margin: 0;
}
